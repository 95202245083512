import './PageSubtitle.scss';

import React from 'react';

interface Props {
  children: string;
}

const PageSubtitle: React.FC<Props> = ({ children }: Props) => (
  <h2 className="page-subtitle">{children}</h2>
);

export default PageSubtitle;
