import './CheckboxInput.scss';

import React, { ChangeEvent } from 'react';
import autobind from "autobind-decorator";

interface Props<K extends string> {
  name: K;
  checked: boolean;
  required: boolean;
  onChange: (value: boolean, name: K) => void | Promise<void>;
  error?: string;
}

class CheckboxInput<K extends string> extends React.Component<Props<K>> {

  @autobind
  handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.onChange(Boolean(e.target.checked), this.props.name);
  }

  render() {
    const {
      name,
      checked,
      required,
      children,
      error,
    } = this.props;

    return (
      <>
        <label className="checkbox-input">
          <input
            type="checkbox"
            name={name}
            checked={checked}
            required={required}
            onChange={this.handleChange}
          />
          <span>
          {children}{required ? '*' : ''}
        </span>
        </label>
        {error && <p className="error-message">{error}</p>}
      </>
    );
  }
}

export default CheckboxInput;
