import './PageTitle.scss';

import React from 'react';

interface Props {
  children: string;
}

const PageTitle: React.FC<Props> = ({ children }: Props) => (
  <h1 className="page-title">{children}</h1>
);

export default PageTitle;
