import './FormPage.scss';

import React, { FormEvent } from 'react';
import { Moment } from "moment";
import DatePicker from "./components/DatePicker";
import autobind from "autobind-decorator";
import { locations } from "./locations";
import type { LocationName } from "./locations";
import SelectInput from "./components/SelectInput";
import TextInput from "./components/TextInput";
import TextAreaInput from "./components/TextareaInput";
import CheckboxInput from "./components/CheckboxInput";
import RadioInput from "./components/RadioInput";
import PageTitle from "./components/PageTitle";
import PageSubtitle from './components/PageSubtitle';
import Divider from "./components/Divider";
import Button from "./components/Button";
import Section from "./components/Section";
import FormFields from "./components/FormFields";

interface Props {
  formErrors: Record<string, string>;
  location: LocationName | null;
  onSubmit: (state: State) => void | Promise<void>;
}

type Reason = 'private' | 'business' | 'merchant';
type TimeOfDay = 'morning' | 'afternoon' | 'evening';

export interface State {
  location: LocationName | null;
  reason: Reason | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  message: string | null;
  timeOfDay: TimeOfDay | null;
  member: number | null;
  date: string | null;
  subscribeNewsletter: boolean;
}

class FormPage extends React.Component<Props, State> {
  state = {
    location: this.props.location ?? null,
    reason: null,
    firstName: null,
    lastName: null,
    email: null,
    message: null,
    timeOfDay: null,
    member: null,
    date: null,
    subscribeNewsletter: false,
  };

  @autobind
  handleInputChange(value: string | boolean | null, name: keyof State) {
    // @ts-ignore
    this.setState({ [name]: value });
  }

  @autobind
  handleLocationSelect(location: LocationName | null) {
    this.setState({ location });
  }

  @autobind
  handleReasonChange(reason: string) {
    this.setState({ reason: reason as Reason });
  }

  @autobind
  handleTimeOfDayChange(timeOfDay: string) {
    this.setState({ timeOfDay: timeOfDay as TimeOfDay });
  }

  @autobind
  handleMemberChange(member: number | null) {
    this.setState({ member });
  }

  @autobind
  handleDateChange(date: Moment | null) {
    this.setState({ date: date?.format('DD.MM.YYYY') ?? null });
  }

  @autobind
  handleSubmit(event: FormEvent) {
    const requiredFields: Array<keyof State> = [
      'location',
      'firstName',
      'lastName',
      'email',
      'message',
      'reason',
      'timeOfDay',
      'date',
    ];

    event.preventDefault();

    for (const requiredField of requiredFields) {
      if (!(this.state[requiredField] ?? null)) {
        return false;
      }
    }

    this.props.onSubmit(this.state);
  }

  render() {
    const { formErrors } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="form" method="POST">
        <Section variant="grey" flat={true}>
          <FormFields>
            <PageTitle>Ihre Terminanfrage</PageTitle>
            <SelectInput<LocationName>
              required={true}
              placeholder="Gewünschten Standort auswählen"
              value={this.state.location}
              name="location"
              onChange={this.handleLocationSelect}
              options={Array.from(Object.keys(locations) as Array<LocationName>).sort().map(value => ({ value, label: locations[value] }))}
              error={formErrors['location']}
            />
            <p className="opening-hours">Bitte beachten Sie unsere Öffnungszeiten Mo.-Fr. 08:00 - 16:00 Uhr</p>
          </FormFields>
        </Section>
        <Divider/>
        <Section variant="grey" flat={true}>
          <FormFields>
            <PageSubtitle>Ihre Kontaktdaten:</PageSubtitle>
            <div className="radio-group">
              <RadioInput<keyof State>
                name="reason"
                value="merchant"
                checked={this.state.reason === 'merchant'}
                onChange={this.handleReasonChange}
                required={true}
              >Händler</RadioInput>
              <RadioInput<keyof State>
                name="reason"
                value="business"
                checked={this.state.reason === 'business'}
                onChange={this.handleReasonChange}
                required={true}
              >Gewerblich</RadioInput>
              <RadioInput<keyof State>
                name="reason"
                value="private"
                checked={this.state.reason === 'private'}
                onChange={this.handleReasonChange}
                required={true}
              >Privat</RadioInput>
              {formErrors['reason'] && <p className="error-message">{formErrors['reason']}</p>}
            </div>
            <TextInput<keyof State>
              type="text"
              name="firstName"
              required={true}
              placeholder="Vorname"
              value={this.state.firstName}
              onChange={this.handleInputChange}
              error={formErrors['firstName']}
            />
            <TextInput<keyof State>
              type="text"
              name="lastName"
              required={true}
              placeholder="Nachname"
              value={this.state.lastName}
              onChange={this.handleInputChange}
              error={formErrors['lastName']}
            />
            <TextInput<keyof State>
              type="email"
              name="email"
              required={true}
              placeholder="E-Mail"
              value={this.state.email}
              onChange={this.handleInputChange}
              error={formErrors['email']}
            />
            <TextAreaInput<keyof State>
              name="message"
              required={true}
              placeholder="Nachricht/Beratungsthema"
              value={this.state.message}
              onChange={this.handleInputChange}
              error={formErrors['message']}
            />
            <label>Gewünschten Zeitraum auswählen:*</label>
            <div className="radio-group">
              <RadioInput<keyof State>
                name="timeOfDay"
                value="morning"
                checked={this.state.timeOfDay === 'morning'}
                onChange={this.handleTimeOfDayChange}
                required={true}
              >Morgens</RadioInput>
              <RadioInput<keyof State>
                name="timeOfDay"
                value="afternoon"
                checked={this.state.timeOfDay === 'afternoon'}
                onChange={this.handleTimeOfDayChange}
                required={true}
              >Vormittags/Mittags</RadioInput>
              <RadioInput<keyof State>
                name="timeOfDay"
                value="evening"
                checked={this.state.timeOfDay === 'evening'}
                onChange={this.handleTimeOfDayChange}
                required={true}
              >Nachmittags</RadioInput>
              {formErrors['timeOfDay'] && <p className="error-message">{formErrors['timeOfDay']}</p>}
            </div>
            <SelectInput<number>
              options={[1, 2, 3, 4].map(value => ({ value, label: value === 1 ? `1 Person` : `${value} Personen` }))}
              value={this.state.member}
              name="member"
              placeholder="Gewünschte Personenanzahl auswählen"
              required={true}
              onChange={this.handleMemberChange}
              error={formErrors['member']}
            />
            <p className="hint">* Pflichtfelder</p>
          </FormFields>
        </Section>
        {this.state.location !== null && (
          <Section variant="white">
            <FormFields>
              <PageSubtitle>Bitte wählen Sie Ihren Wunschtermin:</PageSubtitle>
              <p>
                <b>Hinweis:</b> Es handelt sich um eine Terminanfrage, <b>keine verbindliche Buchung.</b> Sie erhalten
                eine Rückmeldung durch das jeweilige Makita Service-Center an die angegebene E-Mail-Adresse.
              </p>
            </FormFields>
            <DatePicker
              location={this.state.location}
              onDateChange={this.handleDateChange}
            />
            {formErrors['date'] && <p className="error-message">{formErrors['date']}</p>}
          </Section>
        )}
        <Section variant="primary">
          <span className="selected-date">
            {(this.state.location === null && this.state.date === null) && <>Bitte wählen Sie ein Makita Service-Center aus.</>}
            {(this.state.location !== null && this.state.date === null) && <>Bitte wählen Sie ihr Wunschtermin aus.</>}
            {(this.state.location !== null && this.state.date !== null) && <>Ihr Wunschtermin: {this.state.date} im Makita Service-Center {locations[this.state.location]}</>}
          </span>
        </Section>
        <Section variant="white">
          <FormFields>
            <p>Hinweise zur Verarbeitung Ihrer Daten finden Sie in unserer <a href="https://www.makita.de/datenschutz.html" target="_blank" rel="noopener">Datenschutzerklärung</a>.</p>
            <CheckboxInput
              name="subscribeNewsletter"
              checked={this.state.subscribeNewsletter}
              onChange={this.handleInputChange}
              required={false}
              error={formErrors['subscribeNewsletter']}
            >
              Ich möchte den kostenlosen Makita Newsletter erhalten.
            </CheckboxInput>
          </FormFields>
          <div className="button-container">
            <Button type="submit">Terminanfrage absenden</Button>
          </div>
          {formErrors['generic'] && <p className="error-message">{formErrors['generic']}</p>}
        </Section>
      </form>
    );
  }
}

export default FormPage;
