import './App.scss';
import React, { useState } from 'react';
import makitaLogo from './assets/makita-logo.png';
import makitaFooterLogo from './assets/makita_white.png';
import instagramLogo from './assets/instagram.png';
import youtubeLogo from './assets/youtube.png';
import facebookLogo from './assets/facebook.png';
import FormPage, { State } from "./FormPage";
import { LocationName, locations } from "./locations";
import SuccessPage from "./SuccessPage";
import Section from "./components/Section";

function App() {
  const location = new URL(window.location.href).pathname.split('/').pop() ?? '';
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formState, setFormState] = useState<State | null>(null);
  const submitForm = async (formData: State) => {
    try {
      const response = await fetch('.', {
        method: 'POST',
        cache: "no-cache",
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(formData),
      });

      const json = await response.json();

      if (!response.ok || !json.success) {
        setFormErrors(json.errors ?? [])
        return;
      }
    } catch (err) {
      console.error('Server request was invalid.', err);
      return;
    }

    setFormState(formData);
    setShowSuccess(true);
  };

  return (
    <div className="app">
      <header className="header">
        <a href="https://www.makita.de/">
          <img src={makitaLogo} className="logo" alt="logo" />
        </a>
      </header>
      <main>
        {showSuccess ? (
          <SuccessPage formState={formState!} />
        ) : (
          <FormPage
            formErrors={formErrors}
            location={location in locations ? (location as LocationName) : null}
            onSubmit={submitForm}
          />
        )}
      </main>
      <Section variant="dark">
        <div className="social-media">
          <a className="social-media__link" href="https://www.facebook.com/MakitaDeutschland/" target="_blank">
            <img className="social-media__logo" src={facebookLogo} alt="facebook" />
          </a>
          <a className="social-media__link" href="https://www.instagram.com/makita_deutschland/?hl=de" target="_blank">
            <img className="social-media__logo" src={instagramLogo} alt="instagram" />
          </a>
          <a className="social-media__link" href="https://www.youtube.com/user/MakitaDeutschland" target="_blank">
            <img className="social-media__logo" src={youtubeLogo} alt="youtube" />
          </a>
        </div>
      </Section>
      <Section variant="dark" flat={true}>
        <nav className="footer__nav">
          <a href="https://www.makita.de/impressum.html" target="_blank" rel="noreferrer">Impressum</a>
          <a href="https://www.makita.de/datenschutz.html" target="_blank" rel="noreferrer">Datenschutz</a>
          <a href="https://www.makita.de/cookie-richtlinien.html" target="_blank" rel="noreferrer">Cookie-Richtlinien</a>
        </nav>
      </Section>
      <Section variant="dark" flat={true}>
        <p>© Copyright Makita Werkzeug GmbH - All Rights Reserved</p>
        <br/>
        <p>
          Produktabbildungen und Lieferumfang können variieren.
          Änderungen und Irrtümer vorbehalten.
        </p>
      </Section>
      <Section variant="dark">
        <a href="https://www.makita.de/">
          <img src={makitaFooterLogo} className="logo" alt="logo" />
        </a>
      </Section>
    </div>
  );
}

export default App;
