import moment, { Moment } from "moment";
import type { LocationName } from "./locations";

type BlockedDates = { [K in LocationName]: Array<Moment> };

const blockedDates: BlockedDates = {
  hamburg: [
    moment('01-01-23', 'DD-MM-YY'),
    moment('25-01-23', 'DD-MM-YY'),
    moment('26-01-23', 'DD-MM-YY'),
    moment('15-02-23', 'DD-MM-YY'),
    moment('21-03-23', 'DD-MM-YY'),
    moment('22-03-23', 'DD-MM-YY'),
    moment('23-03-23', 'DD-MM-YY'),
    moment('29-03-23', 'DD-MM-YY'),
    moment('07-04-23', 'DD-MM-YY'),
    moment('10-04-23', 'DD-MM-YY'),
    moment('26-04-23', 'DD-MM-YY'),
    moment('01-05-23', 'DD-MM-YY'),
    moment('10-05-23', 'DD-MM-YY'),
    moment('18-05-23', 'DD-MM-YY'),
    moment('24-05-23', 'DD-MM-YY'),
    moment('29-05-23', 'DD-MM-YY'),
    moment('14-06-23', 'DD-MM-YY'),
    moment('15-06-23', 'DD-MM-YY'),
    moment('06-09-23', 'DD-MM-YY'),
    moment('07-09-23', 'DD-MM-YY'),
    moment('13-09-23', 'DD-MM-YY'),
    moment('20-09-23', 'DD-MM-YY'),
    moment('03-10-23', 'DD-MM-YY'),
    moment('27-10-23', 'DD-MM-YY'),
    moment('31-10-23', 'DD-MM-YY'),
    moment('01-11-23', 'DD-MM-YY'),
    moment('08-11-23', 'DD-MM-YY'),
    moment('28-11-23', 'DD-MM-YY'),
    moment('29-11-23', 'DD-MM-YY'),
    moment('30-11-23', 'DD-MM-YY'),
    moment('06-12-23', 'DD-MM-YY'),
    moment('25-12-23', 'DD-MM-YY'),
    moment('26-12-23', 'DD-MM-YY'),
    moment('27-12-23', 'DD-MM-YY'),
    moment('28-12-23', 'DD-MM-YY'),
    moment('29-12-23', 'DD-MM-YY'),
  ],
  berlin: [
    moment('01-01-23', 'DD-MM-YY'),
    moment('10-01-23', 'DD-MM-YY'),
    moment('11-01-23', 'DD-MM-YY'),
    moment('06-02-23', 'DD-MM-YY'),
    moment('07-02-23', 'DD-MM-YY'),
    moment('21-03-23', 'DD-MM-YY'),
    moment('22-03-23', 'DD-MM-YY'),
    moment('07-04-23', 'DD-MM-YY'),
    moment('10-04-23', 'DD-MM-YY'),
    moment('18-04-23', 'DD-MM-YY'),
    moment('19-04-23', 'DD-MM-YY'),
    moment('01-05-23', 'DD-MM-YY'),
    moment('03-05-23', 'DD-MM-YY'),
    moment('04-05-23', 'DD-MM-YY'),
    moment('05-05-23', 'DD-MM-YY'),
    moment('18-05-23', 'DD-MM-YY'),
    moment('29-05-23', 'DD-MM-YY'),
    moment('14-06-23', 'DD-MM-YY'),
    moment('21-06-23', 'DD-MM-YY'),
    moment('13-09-23', 'DD-MM-YY'),
    moment('03-10-23', 'DD-MM-YY'),
    moment('04-10-23', 'DD-MM-YY'),
    moment('05-10-23', 'DD-MM-YY'),
    moment('06-10-23', 'DD-MM-YY'),
    moment('11-10-23', 'DD-MM-YY'),
    moment('17-10-23', 'DD-MM-YY'),
    moment('14-11-23', 'DD-MM-YY'),
    moment('15-11-23', 'DD-MM-YY'),
    moment('21-11-23', 'DD-MM-YY'),
    moment('12-12-23', 'DD-MM-YY'),
    moment('25-12-23', 'DD-MM-YY'),
    moment('26-12-23', 'DD-MM-YY'),
    moment('27-12-23', 'DD-MM-YY'),
    moment('28-12-23', 'DD-MM-YY'),
    moment('29-12-23', 'DD-MM-YY'),
  ],
  lehrte: [
    moment('01-01-23', 'DD-MM-YY'),
    moment('17-01-23', 'DD-MM-YY'),
    moment('18-01-23', 'DD-MM-YY'),
    moment('21-02-23', 'DD-MM-YY'),
    moment('22-02-23', 'DD-MM-YY'),
    moment('23-02-23', 'DD-MM-YY'),
    moment('14-03-23', 'DD-MM-YY'),
    moment('16-03-23', 'DD-MM-YY'),
    moment('21-03-23', 'DD-MM-YY'),
    moment('07-04-23', 'DD-MM-YY'),
    moment('10-04-23', 'DD-MM-YY'),
    moment('26-04-23', 'DD-MM-YY'),
    moment('01-05-23', 'DD-MM-YY'),
    moment('18-05-23', 'DD-MM-YY'),
    moment('29-05-23', 'DD-MM-YY'),
    moment('06-06-23', 'DD-MM-YY'),
    moment('07-06-23', 'DD-MM-YY'),
    moment('12-09-23', 'DD-MM-YY'),
    moment('20-09-23', 'DD-MM-YY'),
    moment('27-09-23', 'DD-MM-YY'),
    moment('03-10-23', 'DD-MM-YY'),
    moment('10-10-23', 'DD-MM-YY'),
    moment('11-10-23', 'DD-MM-YY'),
    moment('12-10-23', 'DD-MM-YY'),
    moment('31-10-23', 'DD-MM-YY'),
    moment('07-11-23', 'DD-MM-YY'),
    moment('14-11-23', 'DD-MM-YY'),
    moment('21-11-23', 'DD-MM-YY'),
    moment('05-12-23', 'DD-MM-YY'),
    moment('06-12-23', 'DD-MM-YY'),
    moment('12-12-23', 'DD-MM-YY'),
    moment('25-12-23', 'DD-MM-YY'),
    moment('26-12-23', 'DD-MM-YY'),
    moment('27-12-23', 'DD-MM-YY'),
    moment('28-12-23', 'DD-MM-YY'),
    moment('29-12-23', 'DD-MM-YY'),
  ],
  gerau: [
    moment('01-01-23', 'DD-MM-YY'),
    moment('24-01-23', 'DD-MM-YY'),
    moment('25-01-23', 'DD-MM-YY'),
    moment('26-01-23', 'DD-MM-YY'),
    moment('16-02-23', 'DD-MM-YY'),
    moment('17-02-23', 'DD-MM-YY'),
    moment('14-03-23', 'DD-MM-YY'),
    moment('15-03-23', 'DD-MM-YY'),
    moment('21-03-23', 'DD-MM-YY'),
    moment('30-03-23', 'DD-MM-YY'),
    moment('07-04-23', 'DD-MM-YY'),
    moment('10-04-23', 'DD-MM-YY'),
    moment('28-04-23', 'DD-MM-YY'),
    moment('01-05-23', 'DD-MM-YY'),
    moment('10-05-23', 'DD-MM-YY'),
    moment('18-05-23', 'DD-MM-YY'),
    moment('29-05-23', 'DD-MM-YY'),
    moment('08-06-23', 'DD-MM-YY'),
    moment('13-06-23', 'DD-MM-YY'),
    moment('14-06-23', 'DD-MM-YY'),
    moment('13-09-23', 'DD-MM-YY'),
    moment('20-09-23', 'DD-MM-YY'),
    moment('03-10-23', 'DD-MM-YY'),
    moment('11-10-23', 'DD-MM-YY'),
    moment('12-10-23', 'DD-MM-YY'),
    moment('13-10-23', 'DD-MM-YY'),
    moment('02-11-23', 'DD-MM-YY'),
    moment('09-11-23', 'DD-MM-YY'),
    moment('16-11-23', 'DD-MM-YY'),
    moment('07-12-23', 'DD-MM-YY'),
    moment('08-12-23', 'DD-MM-YY'),
    moment('14-12-23', 'DD-MM-YY'),
    moment('25-12-23', 'DD-MM-YY'),
    moment('26-12-23', 'DD-MM-YY'),
    moment('27-12-23', 'DD-MM-YY'),
    moment('28-12-23', 'DD-MM-YY'),
    moment('29-12-23', 'DD-MM-YY'),
  ],
  muenchen: [
    moment('01-01-23', 'DD-MM-YY'),
    moment('07-04-23', 'DD-MM-YY'),
    moment('10-04-23', 'DD-MM-YY'),
    moment('01-05-23', 'DD-MM-YY'),
    moment('03-05-23', 'DD-MM-YY'),
    moment('04-05-23', 'DD-MM-YY'),
    moment('18-05-23', 'DD-MM-YY'),
    moment('24-05-23', 'DD-MM-YY'),
    moment('25-05-23', 'DD-MM-YY'),
    moment('29-05-23', 'DD-MM-YY'),
    moment('08-06-23', 'DD-MM-YY'),
    moment('14-06-23', 'DD-MM-YY'),
    moment('20-06-23', 'DD-MM-YY'),
    moment('22-06-23', 'DD-MM-YY'),
    moment('28-06-23', 'DD-MM-YY'),
    moment('05-07-23', 'DD-MM-YY'),
    moment('06-07-23', 'DD-MM-YY'),
    moment('15-08-23', 'DD-MM-YY'),
    moment('20-09-23', 'DD-MM-YY'),
    moment('27-09-23', 'DD-MM-YY'),
    moment('03-10-23', 'DD-MM-YY'),
    moment('17-10-23', 'DD-MM-YY'),
    moment('18-10-23', 'DD-MM-YY'),
    moment('19-10-23', 'DD-MM-YY'),
    moment('25-10-23', 'DD-MM-YY'),
    moment('01-11-23', 'DD-MM-YY'),
    moment('07-11-23', 'DD-MM-YY'),
    moment('15-11-23', 'DD-MM-YY'),
    moment('28-11-23', 'DD-MM-YY'),
    moment('05-12-23', 'DD-MM-YY'),
    moment('06-12-23', 'DD-MM-YY'),
    moment('13-12-23', 'DD-MM-YY'),
    moment('25-12-23', 'DD-MM-YY'),
    moment('26-12-23', 'DD-MM-YY'),
    moment('27-12-23', 'DD-MM-YY'),
    moment('28-12-23', 'DD-MM-YY'),
    moment('29-12-23', 'DD-MM-YY'),
  ],
}

export default blockedDates;