import './Section.scss';

import React, { PropsWithChildren } from "react";
import clsx from "clsx";

type Props = PropsWithChildren<{
  variant: "grey" | "white" | "primary" | "dark";
  flat?: boolean;
}>

const Section: React.FC<Props>= (props: Props) => (
  <section className={clsx('section', `section--${props.variant}`, props.flat && `section--flat`)}>{props.children}</section>
);

export default Section;