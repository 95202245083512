import './FormFields.scss';

import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren<{ gap?: number }>

const FormFields: React.FC<Props>= (props: Props) => {
  const styles = typeof props.gap === 'number'
    ? { gap: `${props.gap}px` }
    : undefined;

  return (
    <div className="form-fields" style={styles}>
      {props.children}
    </div>
  );
};

export default FormFields;