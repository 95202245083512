import './RadioInput.scss';

import React, { ChangeEvent } from 'react';
import autobind from "autobind-decorator";

interface Props<K extends string> {
  name: K;
  checked: boolean;
  required: boolean;
  value: string;
  onChange: (value: string, name: K) => void | Promise<void>;
  error?: string;
}

class RadioInput<K extends string> extends React.Component<Props<K>> {

  @autobind
  handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      this.props.onChange(this.props.value, this.props.name);
    }
  }

  render() {
    const {
      name,
      value,
      checked,
      required,
      children,
      error,
    } = this.props;

    return (
      <>
        <label className="radio-input">
          <input
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={this.handleChange}
            required={required}
          />&nbsp;{children}
        </label>
        {error && <p className="error-message">{error}</p>}
      </>
    );
  }
}

export default RadioInput;
