import React from 'react';
import { State } from "./FormPage";
import { locations } from "./locations";
import PageTitle from "./components/PageTitle";
import PageSubtitle from "./components/PageSubtitle";
import Section from "./components/Section";
import Button from "./components/Button";
import FormFields from "./components/FormFields";

interface Props {
  formState: State;
}

const reasonLabels = {
  business: 'Gewerblich',
  private: 'Privat',
  merchant: 'Händler',
}

const timeOfDayLabels = {
  morning: 'Morgens',
  afternoon: 'Vormittags/Mittags',
  evening: 'Nachmittags',
}

class SuccessPage extends React.Component<Props> {

  render() {
    const { location, firstName, lastName, email, member, message, date, reason, timeOfDay } = this.props.formState;

    return (
      <>
        <Section variant="grey">
          <FormFields>
            <PageTitle>Vielen Dank für Ihre Anfrage!</PageTitle>
          </FormFields>
        </Section>
        <Section variant="white">
          <FormFields>
            <p>
              Ihre Anfrage ist bei uns eingegangen und wird geprüft. <b>Dies ist keine Terminbestätigung.</b> Wir werden Sie zu Ihrer Terminanfrage kontaktieren.
            </p>
            <p>
              <b>Sie erhalten in Kürze eine separate E-Mail mit den Daten Ihrer Terminanfrage. Bitte prüfen Sie auch Ihren SPAM-Ordner.</b>
            </p>
          </FormFields>
        </Section>
        <Section variant="grey">
          <FormFields gap={4}>
            <PageSubtitle>Ihre Terminanfrage:</PageSubtitle>
            <div><b>Standort:</b>&nbsp;{locations[location!]}</div>
            <div><b>Vorname:</b>&nbsp;{firstName}</div>
            <div><b>Nachname:</b>&nbsp;{lastName}</div>
            <div><b>E-Mail:</b>&nbsp;{email}</div>
            <div><b>Nachricht/Beratungsthema:</b>&nbsp;{message}</div>
            <div><b>Gewünschter Zeitraum:</b>&nbsp;{timeOfDayLabels[timeOfDay!]}</div>
            <div><b>Personenanzahl:</b>&nbsp;{member}</div>
            <div><b>Wunschtermin:</b>&nbsp;{date}</div>
            {reason && (<div><b>Art der Anfrage:</b>&nbsp;{reasonLabels[reason!]}</div>)}
          </FormFields>
        </Section>
        <Section variant="white">
          <FormFields gap={4}>
            <p>Bitte beachten Sie die Öffnungszeiten des Makita Service-Centers: Mo.-Fr. 08:00 - 16:00 Uhr. Im Makita Service-Center ist es nicht möglich, Maschinen, Zubehör oder Ersatzteile zu erwerben.</p>
          </FormFields>
          <div className="button-container">
            <Button href="http://makita.de/" type="submit">Zurück zu makita.de</Button>
          </div>
        </Section>
      </>
    );
  }
}

export default SuccessPage;
