import './SelectInput.scss';

import React, { ChangeEvent } from 'react';
import autobind from "autobind-decorator";
import clsx from "clsx";

export interface Option<T extends string | number | undefined> {
  value: T;
  label: string;
}

interface Props<T extends string | number | undefined> {
  options: Array<Option<T>>;
  name: string;
  value: T | null;
  placeholder: string;
  required: boolean;
  onChange: (value: T | null, name: string) => void | Promise<void>;
  error?: string;
}

class SelectInput<T extends string | number | undefined> extends React.Component<Props<T>> {

  @autobind
  handleChange(e: ChangeEvent<HTMLSelectElement>) {
    this.props.onChange(e.target.value as T || null, this.props.name);
  }

  render() {
    const { value, name, required, placeholder, options, error } = this.props;

    return (
      <>
        <select className={clsx('select-input', value !== null && 'has-value')} name={name} required={required} value={value ?? ''} onChange={this.handleChange}>
          <option value="" disabled={true}>{placeholder}{required && '*'}</option>
          {options.map(({ value, label }) => <option key={`${value}-${label}`} value={value}>{label}</option>)}
        </select>
        {error && <p className="error-message">{error}</p>}
      </>
    );
  }
}

export default SelectInput;
