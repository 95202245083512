import './index.scss';

import 'moment/locale/de';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";
import App from './App';

moment.locale('de');

ReactDOM.render((
  <React.StrictMode>
    <App />
  </React.StrictMode>
), document.getElementById('root') as HTMLElement);
