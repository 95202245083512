import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';
import React from 'react';
import { DayPickerSingleDateController } from "react-dates";
import moment, { Moment } from "moment";
import autobind from "autobind-decorator";
import blockedDates from "../blockedDates";
import type { LocationName } from "../locations";

interface Props {
  location: LocationName;
  onDateChange: (date: Moment | null) => void;
}

interface State {
  date: Moment | null;
  month: Moment;
  focused: boolean;
}

class Datepicker extends React.Component<Props, State> {
  state = {
    focused: false,
    month: moment(),
    date: null,
  };

  @autobind
  isOutsideRange(day: Moment): boolean {
    if (day.weekday() === 5 || day.weekday() === 6) {
      return true;
    }

    if (day.isBefore(moment().add(2, 'day'), 'day') || day.isAfter(moment(), 'year')) {
      return true;
    }

    for (const blockedDate of blockedDates[this.props.location]) {
      if (day.isSame(blockedDate, 'day')) {
        return true;
      }
    }

    return false;
  }

  @autobind
  onFocusChange({ focused }: { focused: boolean }): void {
    this.setState({ focused });
  }

  @autobind
  onMonthChange(month: Moment): void {
    this.setState({ month });
  }

  @autobind
  onDateChange(date: Moment | null): void {
    this.setState({ date });
    this.props.onDateChange(date);
  }

  render() {
    return (
      <DayPickerSingleDateController
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={1}
        firstDayOfWeek={1}
        daySize={40}
        noBorder={true}
        date={this.state.date}
        focused={this.state.focused}
        onDateChange={this.onDateChange}
        onFocusChange={this.onFocusChange}
        initialVisibleMonth={() => this.state.month}
        isOutsideRange={this.isOutsideRange}
        noNavPrevButton={this.state.month.isSameOrBefore(moment(), 'month')}
        noNavNextButton={this.state.month.isSameOrAfter(moment('31-12-23', 'DD-MM-YY'), 'month')}
        onNextMonthClick={this.onMonthChange}
        onPrevMonthClick={this.onMonthChange}
      />
    );
  }
}

export default Datepicker;
