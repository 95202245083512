import './TextAreaInput.scss';

import React, { ChangeEvent } from 'react';
import autobind from "autobind-decorator";

interface Props<K extends string> {
  name: K;
  value: string | null;
  placeholder: string;
  required: boolean;
  onChange: (value: string | null, name: K) => void | Promise<void>;
  error?: string;
}

class TextAreaInput<K extends string> extends React.Component<Props<K>> {

  @autobind
  handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    this.props.onChange(e.target.value as string || null, this.props.name);
  }

  render() {
    const {
      name,
      required,
      placeholder,
      value,
      error,
    } = this.props;

    return (
      <>
        <textarea
          className="textarea-input"
          name={name}
          required={required}
          placeholder={placeholder + (required ? '*' : '')}
          onChange={this.handleChange}
          maxLength={255}
          rows={5}
        >
          {value}
        </textarea>
        {error && <p className="error-message">{error}</p>}
      </>
    );
  }
}

export default TextAreaInput;
