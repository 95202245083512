import './TextInput.scss';

import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import autobind from "autobind-decorator";

interface Props<K extends string> {
  type: HTMLInputTypeAttribute;
  name: K;
  value: string | null;
  placeholder: string;
  required: boolean;
  onChange: (value: string | null, name: K) => void | Promise<void>;
  error?: string;
}

class TextInput<K extends string> extends React.Component<Props<K>> {

  @autobind
  handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.onChange(e.target.value as string || null, this.props.name);
  }

  render() {

    const {
      name,
      type,
      required,
      placeholder,
      value,
      error,
    } = this.props;

    return (
      <>
        <input
          className="text-input"
          type={type}
          name={name}
          value={value ?? ''}
          required={required}
          placeholder={placeholder + (required ? '*' : '')}
          onChange={this.handleChange}
        />
        {error && <p className="error-message">{error}</p>}
      </>
    );
  }
}

export default TextInput;
