import './Button.scss';

import React, { PropsWithChildren } from "react";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: () => void | Promise<void>;
}

interface LinkProps {
  href: string;
  target?: string;
}

type Props = PropsWithChildren<ButtonProps | LinkProps>;

const Button: React.FC<Props> = (props: Props) => ('href' in props) ? (
  <a className="button" href={props.href} target={props.target}>{props.children}</a>
) : (
  <button className="button" type={props.type} onClick={props.onClick}>{props.children}</button>
);

export default Button;